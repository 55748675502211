import { usePartnershipQuery } from "@query";
import React from "react";
import Img, { FluidObject } from "gatsby-image";
import styled from "styled-components";
import { Container, H2 } from "@util/standard";
import { Carousel } from "antd";

const StyledA = styled.a`
  margin: auto;
`;

const StyledCarouselContainer = styled(Container)`
  .ant-carousel {
    width: 100%;
    margin: auto;
  }

  .slick-track {
    margin: auto;
  }
`;

const PartnerSlider = () => {
  const allSanityPartner = usePartnershipQuery();

  if (allSanityPartner == null) return null;

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    draggable: true,
  };

  return (
    <StyledCarouselContainer width="80%" margin="auto" flexDirection="column">
      <H2 width="100%" textAlign="center" fontSize={28}>
        Our Partners / Sponsors
      </H2>

      <Carousel {...carouselSettings}>
        {allSanityPartner.nodes.map((partner, index) => {
          return (
            <Container>
              <StyledA href={partner.link} target="_blank">
                <Img
                  fluid={partner.logo?.asset?.fluid}
                  style={{ width: 150, height: 150 }}
                  imgStyle={{ objectFit: "contain" }}
                />
              </StyledA>
            </Container>
          );
        })}
      </Carousel>
    </StyledCarouselContainer>
  );
};

export default PartnerSlider;
