import React, { useState } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import Img, { FluidObject } from "gatsby-image";

import { Container, P, H3 } from "../../../util/standard";
import {
  TABLET_BREAKPOINT,
  MOBILE_BREAKPOINT,
  icons,
  colors,
} from "../../../util/constants";
import { SanityNews } from "../../../../graphql-types";

interface Props {
  article: SanityNews;
}

const PreviewContainer = styled.div`
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 94%;
  }

  &:hover {
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 300px;
  z-index: -9999;
  position: relative;
`;

const Date = styled(P)`
  color: white;
  background-color: #e61928;
  position: absolute;
  bottom: -16px;
  padding: 10px 15px;
`;

const Arrow = styled.img<{ hoverEffect: boolean }>`
  color: white;
  background-color: ${colors.green};
  position: absolute;
  width: 10%;
  min-width: 60px;
  height: 12.6%;
  bottom: 0px;
  left: 109px;
  padding: 10px;

  ${({ hoverEffect }) => hoverEffect && `background-color: ${colors.darkBlue}`};
  transition: background-color 0.2s;
`;

const Title = styled(H3)`
  padding: 30px 30px 0px 30px;
  font-size: 24px;
  color: #004696;
`;

const SubText = styled(P)`
  width: 90%;
  padding: 0px 30px 30px 30px;
`;
const FeatureImg = styled(Img)<{ hoverEffect: boolean }>`
  width: 100%;
  height: 100%;
  ${({ hoverEffect }) => hoverEffect && `opacity: 0.8`};
  transition: opacity 0.2s;
`;

function NewsPreview(props: Props) {
  const { article } = props;
  const {
    articleTitle,
    articleDate,
    articleSubText,
    articleImage,
    slug,
  } = article;
  const [hoverEffectOn, toggleHoverEffectOn] = useState(false);

  const dateConvert = (date: string) => {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const processDate = date.split("-");
    const month = Number(processDate[1]);
    const convertedMonth =
      processDate[2] + " " + months[month - 1] + " " + processDate[0];
    return convertedMonth;
  };

  return (
    <PreviewContainer
      onMouseEnter={() => toggleHoverEffectOn(true)}
      onMouseLeave={() => toggleHoverEffectOn(false)}
      onClick={() => navigate(`/news/${slug.current}`)}
    >
      <ImageWrapper>
        <FeatureImg
          hoverEffect={hoverEffectOn}
          fluid={articleImage?.asset?.fluid as FluidObject}
          alt={articleImage?.asset.title ?? "article-preview-image"}
        />
        <Container>
          {articleDate && <Date color="black">{dateConvert(articleDate)}</Date>}
          <Arrow
            hoverEffect={hoverEffectOn}
            src={icons.submitArrow}
            alt="news-article-link-arrow-icon"
          />
        </Container>
      </ImageWrapper>
      <Title color="black">{articleTitle}</Title>
      <SubText color="black">{articleSubText}</SubText>
    </PreviewContainer>
  );
}

export default NewsPreview;
