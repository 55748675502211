import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import Hero from "../components/shared/Hero";
import OurProgramsBlock from "../components/shared/OurProgramsBlock";
import DonateBlock from "../components/shared/DonateBlock";
import FeaturedNews from "../components/shared/FeaturedNews";
import ImpactBlock from "../components/shared/ImpactBlock";
import Testimonials from "../components/shared/Testimonials";
import { Query } from "../../graphql-types";
import FeaturedNewsSlider from "../components/home/FeaturedNewsSlider";
import SEO from "../components/shared/Seo";
import { Container } from "../util/standard";
import { TABLET_BREAKPOINT } from "../util/constants";
import PartnerSlider from "@components/partnership/partnershipSlider";

const IframeStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-right: 72px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    justify-content: center;
    margin-right: 0;
  }
`;

const IframeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

export default function Home() {
  const { allSanityHomeBlock }: Query = useStaticQuery(graphql`
    {
      allSanityHomeBlock {
        edges {
          node {
            homeHero {
              heroText {
                _rawContent
              }
              heroLogo {
                asset {
                  fluid {
                    srcWebp
                    srcSetWebp
                    srcSet
                    src
                    ...GatsbySanityImageFluid_noBase64
                  }
                  title
                }
              }
              heroImage {
                asset {
                  fluid {
                    srcWebp
                    srcSetWebp
                    srcSet
                    src
                    ...GatsbySanityImageFluid_noBase64
                  }
                  title
                }
              }
            }
            seoBlock {
              pageDescription
              pageKeyWords
              pageTitle
              ogImage {
                asset {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const { aboutUsBlock, homeHero, seoBlock } = allSanityHomeBlock.edges[0].node;

  return (
    <div>
      <SEO seoData={seoBlock} slug="" />
      <Hero
        isHomeHero
        heroText={homeHero.heroText._rawContent}
        heroImage={homeHero.heroImage}
        heroIcon={homeHero.heroLogo}
      />
      <ImpactBlock reversed />
      <PartnerSlider />
      <FeaturedNews />
      <ImpactBlock />
      <OurProgramsBlock />
      {/* <Testimonials /> */}
      <IframeContainer>
        <Container width="80%" tabletDimensions={{ width: "100%" }}>
          <DonateBlock />
        </Container>
        <IframeStyle>
          <iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FWarriors-Community-Foundation-112017764404622%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            width="340"
            height="500"
            scrolling="no"
            frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </IframeStyle>
      </IframeContainer>
    </div>
  );
}
