import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT, colors } from '../../util/constants';

import FeaturedNewsSlider from '../home/FeaturedNewsSlider';
import { H2 } from '../../util/standard';
import NewsPreview from './sub/NewsPreview';
import { Query } from '../../../graphql-types';


const FeaturedNewsWrapper = styled.div`
width: 80%;
margin: 100px auto;

@media only screen and (max-width: ${MOBILE_BREAKPOINT}px){
  margin:50px auto;
}
`;

const ArticlesContainer = styled.div`
width: 100%;
display: grid;
grid-template-columns: repeat(2, 1fr);
column-gap: 30px;

`;

const HeaderBlock = styled.div`
display:grid;
grid-template-columns: auto 1fr;
grid-gap:22px;
margin: 20px auto;
`;

const StyledH2 = styled(H2)`
color: black;
display: inline-block;
margin-bottom:0;
`;

const ProgramLink = styled.a`
  width: max-content;
  display: inline-grid;
  align-items: center;
  color: ${colors.green};
  text-decoration: underline;
  &:hover {
    color: ${colors.blue};
    text-decoration: underline;

  }
`;

function FeaturedNews() {


  const [isMobileWidth, toggleIsMobileWidth] = useState(false);
  const { sanityHomeBlock }: Query = useStaticQuery(graphql`
   {
    sanityHomeBlock {
      homePageFeaturedArticles {
        selectedArticles {
            id
          articleDate
          articleTitle
          articleSubText
          articleImage {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
              }
              title
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`);

  useEffect(() => {
    checkForMobileWidth();
    window.addEventListener('resize', checkForMobileWidth);
  });

  const checkForMobileWidth = () => {
    window.innerWidth <= MOBILE_BREAKPOINT ? toggleIsMobileWidth(true) : toggleIsMobileWidth(false);
  };
  const dateConvert = (date: string) => {
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const processDate = date.split('-');
    const month = Number(processDate[1]);
    const convertedMonth = processDate[2] + " " + months[month - 1] + " " + processDate[0];
    return convertedMonth;
  };

  const articles = sanityHomeBlock.homePageFeaturedArticles.selectedArticles;
  if (!isMobileWidth) {
    return (
      <FeaturedNewsWrapper>
        <HeaderBlock>
          <StyledH2 fontWeight="bold">News</StyledH2>
          <ProgramLink onClick={() => navigate("/news")}>Read more posts</ProgramLink>
        </HeaderBlock>
        <ArticlesContainer>
          {articles.map((article, index) => {
            if ((index >= 0) && (index <= 1)) {
              return (
                <NewsPreview key={index} article={article} />
              );
            }
          }
          )}
        </ArticlesContainer>
      </FeaturedNewsWrapper>
    );
  } else {
    return (
      <FeaturedNewsSlider />
    );
  }
}

export default FeaturedNews;
