import React, { useRef } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { Carousel } from "antd"

import NewsPreview from '../shared/sub/NewsPreview'
import { Container, H1, H2, P } from '../../util/standard'
import { icons, TABLET_BREAKPOINT, MOBILE_BREAKPOINT, colors } from '../../util/constants'
import { Query } from '../../../graphql-types'

const Wrapper = styled(Container)`
  position: relative;
  width: 100%;
  margin:100px auto;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin:0px auto;
  }
`

const TestimonialsWrapper = styled(Container)`
  width: 80%;
  margin: 0 auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: block;
  }
    
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 86%;
  }
`

const StyledH2 = styled(H2)`
color: black;
display: inline-block;
margin-bottom:0;
`

const ProgramLink = styled.a`
  color: #00A032;
  display:inline-grid;
  align-items:center;
  text-decoration: underline;
  width: max-content;
`

const TextWrapper = styled.div`
 margin: 90px auto 60px auto;
 width: 20%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  margin:0;
  }
`

const HeaderBlock = styled.div`
display:grid;
grid-template-columns: auto 1fr;
grid-gap:22px;
margin: 20px auto;
width:94%;
`

const CarouselWrapper = styled.div`
 margin: 90px auto 50px auto;
 width: 45%;
 position: relative;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin: 0px;
    display:flex;
    flex-direction:column-reverse;
  }

`

const CarouselNav = styled.div`
@media (max-width:${MOBILE_BREAKPOINT}px){
    margin: 20px 0;
    display: flex;
    gap: 25px;
    position: absolute;
    top: 0.5%;
    right: 9%;
}
`
const LeftArrow = styled.img`
position: absolute;
top: 35%;
filter: brightness(0.1);

&:hover{
  cursor:pointer;
}
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    top: 0;
    position: initial;
  }

`

const RightArrow = styled.img`
position: absolute;
top: 35%;
right: 0px;
filter: brightness(0.1);

&:hover{
  cursor:pointer;
}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    top: 0;
    right:auto;
    position: initial;
  }
`

const StyledCarousel = styled(Carousel)`
`

const FeaturedNewsSlider = () => {

  const { allSanityNews }: Query = useStaticQuery(graphql`
    {
      allSanityNews {
    edges {
      node {
        id
        articleDate
        articleTitle
        articleSubText
        articleImage {
          asset {
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src
            }
            title
          }
        }
      }
    }
  }
  }
  `)

  const carouselSlider = useRef(null)

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  }

  return (
    <Wrapper>
      <TestimonialsWrapper>
        <TextWrapper>
          <HeaderBlock>
            <StyledH2 fontWeight="bold">News</StyledH2>
            <ProgramLink onClick={() => navigate("/news")}>Read more posts</ProgramLink>
          </HeaderBlock>
        </TextWrapper>
        <CarouselNav>
          <div><LeftArrow src={icons.leftArrow} onClick={() => { carouselSlider?.current?.prev() }} /></div>
          <div><RightArrow src={icons.rightArrow} onClick={() => { carouselSlider?.current?.next() }} /></div>
        </CarouselNav>
        <CarouselWrapper>
          <StyledCarousel {...carouselSettings} ref={carouselSlider} >
            {allSanityNews.edges.map((article, index: number) => (
              <NewsPreview key={index} article={article.node} />
            ))}
          </StyledCarousel>
        </CarouselWrapper>
      </TestimonialsWrapper>
    </Wrapper>
  )
}

export default FeaturedNewsSlider
