import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const PartnershipQuery = () => {
  const { allSanityPartner }: Query = useStaticQuery(graphql`
    {
      allSanityPartner {
        nodes {
          ...sanityPartner
        }
      }
    }
  `);

  return allSanityPartner;
};

export default PartnershipQuery;
