import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const PartnershipSections = () => {
  const { allSanityPartnershipSections }: Query = useStaticQuery(graphql`
    {
      allSanityPartnershipSections(sort: { fields: order, order: ASC }) {
        nodes {
          _id
          _key
          partnerLevel
          title
          description {
            _rawContent
          }
          partners {
            ...sanityPartner
          }
        }
      }
    }
  `);

  return allSanityPartnershipSections;
};

export default PartnershipSections;
